import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./support.scss"


const CancellationRefundPolicyPage = () => {
  return (
    <Layout>
      <Seo title="Cancellation Refund" />
      <div className="contentalign">
      <h3 className='heading'>Refund Policy </h3>        
        <p>Itineraries and guides once purchased cannot be refunded.</p>
        <h3>Re-Downloading</h3>
        <p>    
            If an ebook becomes corrupted or requires replacement during the normal course of use, you can re-download it from the order notification email or reach out to us at visa2explore@gmail.com along with your Order ID
        </p>
      </div>
    </Layout>)
}

export default CancellationRefundPolicyPage

